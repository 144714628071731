/**
 * @typedef {Object} OrderDeliveryAddress
 * @property {number} id
 * @property {string} name
 * @property {string} mainInfo
 * @property {string} location
 * @property {string} zip
 * @property {boolean} defaultDeliveryAddress
 */

/**
 * @param {OrderDeliveryAddress[]}deliveryAddresses
 * @returns {OrderDeliveryAddress}
 */
export const findDefaultDeliveryAddress = (deliveryAddresses) =>
  deliveryAddresses.find((address) => address.defaultDeliveryAddress) ??
  deliveryAddresses[0];

