import {Spinner} from 'react-bootstrap';
import {ContainerTitle} from '../../components/Theme/theme.elements';
import {BtnModificarDireccion, ContainerDireccion, ContainerMenu, DetailsDir} from '../../ui';
import {Link} from 'react-router-dom';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {useOrderDeliveryAddress} from '../application/useOrderDeliveryAddress';
import React from 'react';

export const DeliveryAddress = () => {
  const { defaultDeliveryAddressRemoteData } = useOrderDeliveryAddress();

  return (
    <ContainerMenu>
      <ContainerTitle>
        Dirección de entrega
      </ContainerTitle>
      <ContainerDireccion>
        <RemoteDataUI remoteData={defaultDeliveryAddressRemoteData}>
          <RemoteDataUI.Pending>
            <div className="text-center m-4">
              <Spinner />
            </div>
          </RemoteDataUI.Pending>
          <RemoteDataUI.Success>
            {
              deliveryAddress => (
                <>
                  <h5>Dirección</h5>
                  <span>{deliveryAddress.mainInfo}</span><br />
                  <DetailsDir>
                    <summary>
                      Mostrar dirección completa
                    </summary>
                    <p><b>Nombre: </b>{deliveryAddress.name}</p>
                    <p><b>Código postal: </b>{deliveryAddress.zip}</p>
                    <p><b>Provincia ó región: </b>{deliveryAddress.country || 'N/A'}</p>
                  </DetailsDir>
                  <Link to={'/cuenta/direcciones'}>
                    <BtnModificarDireccion>
                      Modificar dirección de entrega
                    </BtnModificarDireccion>
                  </Link>
                </>
              )
            }
          </RemoteDataUI.Success>
          <RemoteDataUI.Failure>
            <div className="alert alert-danger">
              Imposible cargar dirección de envio por defecto. Contacte con nosotros.
            </div>
          </RemoteDataUI.Failure>
        </RemoteDataUI>
      </ContainerDireccion>
    </ContainerMenu>
  );
};