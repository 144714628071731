import {DealType} from '../domain/DealSelection.model';

export const GiftDealsSelectorUI = ({
  giftDeals,
  onDealSelection,
  selection
}) => {
  return (
    <div>
      {
        giftDeals?.length > 0 && (
          <div>
            <strong className="m-0 text-primary">
              Promociones con obsequios
            </strong>
            <div className="table-responsive">
              <table className="table table-borderless">
                <thead>
                <tr>
                  <th>{/*Checkbox*/}</th>
                  <th>Descripción</th>
                  <th>Cant. min.</th>
                  <th>Precio obsequio</th>
                  <th>Precio pack</th>
                </tr>
                </thead>
                <tbody className="fw-normal">
                {
                  giftDeals.map((deal, index) => (
                    <tr key={index}>
                      <td>
                        <input type="radio"
                               name="giftDeal"
                               checked={selection?.value === deal}
                               onChange={() => onDealSelection({ type: DealType.PROMO, value: deal })}
                        />
                      </td>
                      <td>{deal.description}</td>
                      <td>{deal.RLD_Cant__Min__Promocion} Uds</td>
                      <td>{deal.precio_neto.toFixed(2)}€</td>
                      <td>{deal.packTotalPrice.toFixed(2)}€</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </div>
  );
};