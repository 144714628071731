import ApiClient from '../../API/api';

const ORDER_ENDPOINT = 'account/orders';

/**
 * @param unsafeOrder
 * @returns {Order}
 */
const parseOrder = (unsafeOrder)=> {
  if (!('id' in unsafeOrder)) {
    throw new Error('Invalid Order.');
  }
  if (!('code' in unsafeOrder)) {
    throw new Error('Invalid Order.');
  }
  return unsafeOrder;
};

// TODO: use remote data
/**
 * @param {number} addressId
 * @param {string} userComment
 * @returns {Promise<Order>}
 */
export const placeOrder = (addressId, userComment) => {
  return ApiClient.performRequest(
    ORDER_ENDPOINT,
    { method: 'POST', body: { addressId, userComment } }
  ).then(parseOrder);
};

// TODO: use remote data
/**
 * @returns {Promise<Order[]>}
 */
export const getAll = () => {
  return ApiClient.performRequest(ORDER_ENDPOINT);
};
