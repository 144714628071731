/**
 * @typedef {Object} ProductSelection
 * @property {number} productId - The unique identifier for the selected product.
 * @property {Price?} price - Price details of the product.
 * @property {number} quantity - The quantity of the product selected.
 * @property {Discount} discount - Discount details applied to the product.
 * @property {BcDiscounts} bcDiscounts - Breakdown of additional discounts.
 * @property {GiftProduct?} giftProduct - Optional gift product details.
 */

/**
 * @typedef {Object} Price
 * @property {number} basePrice - The base price of the product.
 * @property {number} sellingPrice - The selling price after discounts.
 */

/**
 * @typedef {Object} Discount
 * @property {number} value - The discount value in currency.
 * @property {string} percentage - The discount percentage.
 * @property {string} text - Descriptive text for the discount.
 */

/**
 * @typedef {Object} BcDiscounts
 * @property {number} descBc1 - Discount condition 1.
 * @property {number} descBc2 - Discount condition 2.
 * @property {number} descBc3 - Discount condition 3.
 * @property {number} descBc4 - Discount condition 4.
 * @property {number} descBc5 - Discount condition 5.
 * @property {number} descBc6 - Discount condition 6.
 * @property {number} descBc7 - Discount condition 7.
 * @property {number} descBc8 - Discount condition 8.
 * @property {number} descBc9 - Discount condition 9.
 * @property {number} descBc10 - Discount condition 10.
 */

import {DealType} from './DealSelection.model';
import {extractSingleQuantityOffer} from './ProductDetails.model';

/**
 * @typedef {Object} GiftProduct
 * @property {number} id - The unique identifier for the gift product.
 * @property {number} quantity - Quantity of the gift product.
 * @property {Price} price - Price details for the gift product.
 * @property {BcDiscounts} bcDiscount - Additional discounts for the gift product.
 */


export const NoBcDiscounts = {
  descBc1: 0,
  descBc2: 0,
  descBc3: 0,
  descBc4: 0,
  descBc5: 0,
  descBc6: 0,
  descBc7: 0,
  descBc8: 0,
  descBc9: 0,
  descBc10: 0
};

/**
 * @param {ProductDetails} productDetails
 * @param {Lot} lot
 * @param quantity
 * @returns {ProductSelection}
 */
export const buildWithLot = (productDetails, lot, quantity) => {
  const price = {
    basePrice: lot.mejorpreciotachado,
    sellingPrice: lot.mejorprecio
  };
  const discount = {
    value: (lot.mejorpreciotachado - lot.mejorprecio) * quantity,
    percentage: lot.porcentajeAhorro,
    text: lot.Descuentos1
  };
  let bcDiscounts = {
    ...NoBcDiscounts,
    descBc9: lot.descBc9
  };
  if (lot.THR_Sales_Price === 0) {
    bcDiscounts = {
      ...NoBcDiscounts,
      descBc1: lot.descBc1,
      descBc2: lot.descBc2,
      descBc3: lot.descBc3,
      descBc4: lot.descBc4,
      descBc9: lot.descBc9
    };
  }
  return { productId: productDetails.id, price, quantity, bcDiscounts, discount };
};

/**
 * @param {ProductDetails} productDetails
 * @param {Offer} offer
 * @param {number} quantity
 * @returns {ProductSelection}
 */
export const buildWithOffer = (productDetails, offer, quantity) => {
  return {
    productId: productDetails.id,
    price: buildOfferPrice(productDetails, offer),
    discount: buildOfferDiscount(productDetails, offer, quantity),
    bcDiscounts: buildOfferBcDiscount(productDetails, offer),
    quantity
  };
};

const buildOfferPrice = (productDetails, offer) => {
  const price = productDetails.priceDetailedInformation?.price;
  return price && {
    basePrice: price.originalPrice,
    sellingPrice: offer.price
  };
};

const buildOfferDiscount = (productDetails, offer, quantity) => {
  const price = productDetails.priceDetailedInformation?.price;
  return price && {
    value: (price.originalPrice - offer.price) * quantity,
    percentage: offer.descuento,
    text: offer.Descuentos1
  };
};

const buildOfferBcDiscount = (productDetails, offer) => {
  const price = productDetails.priceDetailedInformation?.price;
  let result = NoBcDiscounts;
  if (!!price) {
    result = {
      ...NoBcDiscounts,
      descBc1: price.descBc1,
      descBc2: price.descBc2,
      descBc3: price.descBc3,
      descBc4: price.descBc4,
      descBc5: offer.descBc5,
      descBc6: offer.descBc6,
      descBc7: offer.descBc7,
      descBc8: offer.descBc8
    };
  }
  return result;
};

/**
 * @param {ProductDetails} productDetails
 * @param {Promo} promo
 * @param {number} quantity
 * @returns {ProductSelection}
 */
export const buildWitPromo = (productDetails, promo, quantity) => {
  let price = {
    sellingPrice: promo.RLD_Precio_Producto,
    basePrice: promo.RLD_Precio_Producto
  };
  const discount = {
    percentage: promo.descuento,
    text: promo.Descuentos1
  };
  let bcDiscounts = { ...NoBcDiscounts };
  if (promo.RLD_Precio_Producto === 0) {
    price = {
      sellingPrice: promo.mejorprecio,
      basePrice: promo.mejorpreciotachado
    };
    bcDiscounts = buildBcDiscounts(productDetails);
  }
  const giftProduct = {
    id: promo.THR_Charge_Item_No_[0].id,
    quantity: Math.floor(quantity / promo.RLD_Cant__Min__Promocion) * promo.THR_Quantity,
    price: { sellingPrice: promo.precio_neto, basePrice: promo.THR_Sales_Price },
    bcDiscounts: {
      ...NoBcDiscounts,
      descBc10: promo.descBc10
    }
  };
  return { productId: productDetails.id, price, quantity, bcDiscounts, discount, giftProduct };
};

/**
 * @param {ProductDetails} productDetails
 * @param quantity
 * @returns {ProductSelection}
 */
export const buildWithoutDeal = (productDetails, quantity) => {
  return {
    productId: productDetails.id,
    price: buildPrice(productDetails),
    discount: buildDiscount(productDetails, quantity),
    bcDiscounts: buildBcDiscounts(productDetails),
    quantity
  };
};

/**
 * @param {ProductDetails} productDetails
 * @returns {{sellingPrice: (number|number|*), basePrice: (number|number|*)}}
 */
const buildPrice = (productDetails) => {
  const price = productDetails.priceDetailedInformation?.price;
  return price && {
    basePrice: price.originalPrice,
    sellingPrice: price.bestPrice
  };
};

const buildDiscount = (productDetails, quantity) => {
  const price = productDetails.priceDetailedInformation?.price;
  return price && {
    value: (price.originalPrice - price.bestPrice) * quantity,
    percentage: null,
    text: price.discountLine
  };
};

const buildBcDiscounts = (productDetails) => {
  const price = productDetails.priceDetailedInformation?.price;
  let result = NoBcDiscounts;
  if (!!price) {
    result = {
      descBc1: price.descBc1,
      descBc2: price.descBc2,
      descBc3: price.descBc3,
      descBc4: price.descBc4,
      descBc5: price.descBc5,
      descBc6: price.descBc6,
      descBc7: price.descBc7,
      descBc8: price.descBc8,
      descBc9: price.descBc9,
      descBc10: price.descBc10
    };
  }
  return result;
};

export const defaultDealSelection = (productDetails) => {
  const result = null;
  const singleQuantityOffer = extractSingleQuantityOffer(productDetails);
  if (!!singleQuantityOffer) {
    return ({ type: DealType.OFFER, value: singleQuantityOffer });
  }
  return result;
};

export const createProductSelectionHandler = (productDetails, initialQuantity) => {
  let prevQuantity = initialQuantity ?? 0;
  return (deal, newQuantity) => {
    let result;
    switch (deal?.type) {
      case DealType.LOT:
        result = buildWithLot(productDetails, deal.value, prevQuantity);
        break;
      case DealType.PROMO:
        const adjustedQuantity = adjustToNearestChunk(prevQuantity, newQuantity, deal.value.RLD_Cant__Min__Promocion);
        result = buildWitPromo(productDetails, deal.value, adjustedQuantity);
        break;
      case DealType.OFFER:
        result = buildWithOffer(productDetails, deal.value, newQuantity < deal.value.cantidad ? deal.value.cantidad : newQuantity);
        break;
      default:
        result = buildWithoutDeal(productDetails, prevQuantity);
    }
    prevQuantity = newQuantity;
    return result;
  };
};

const adjustToNearestChunk = (prevValue, newValue, chunk) => {
  if (newValue < chunk) {
    return chunk;
  }
  const chunks = Math.ceil(Math.abs(newValue - prevValue) / chunk);
  if (prevValue < newValue) {
    return prevValue + (chunks * chunk);
  }
  return prevValue - (chunks * chunk);
};

/**
 *
 * @param {ProductSelection} productSelection
 * @return {Price}
 */
export const calculateTotalPrice = (productSelection) => {
  let result = {
    sellingPrice: productSelection.price.sellingPrice * productSelection.quantity,
    basePrice: productSelection.price.basePrice * productSelection.quantity
  };
  if (!!(productSelection?.giftProduct)) {
    result.sellingPrice += productSelection.giftProduct.price.sellingPrice * productSelection.giftProduct.quantity;
    result.basePrice += productSelection.giftProduct.price.basePrice * productSelection.giftProduct.quantity;
  }
  return result;
};


/**
 *
 * @param {ProductSelection} productSelection
 * @return {Discount}
 */
export const calculateTotalDiscount = (productSelection) => {
  const { basePrice, sellingPrice } = calculateTotalPrice(productSelection);
  if (!!(productSelection?.giftProduct)) {
    return {
      value: (basePrice - sellingPrice),
      percentage: null,
      text: `${(basePrice - sellingPrice) / basePrice}`
    };
  }
  return productSelection.discount;
};
