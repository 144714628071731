import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {initial, mapRemoteData} from '../../common/domain/RemoteData.model';
import {findDefaultDeliveryAddress} from '../domain/OrderDeliveryAddress.model';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {getDeliveryAddresses} from '../../address/infrastructure/AddressApi';

/**
 * @param {Address}
 * @returns {{zip, name, defaultDeliveryAddress, location, id, mainInfo}}
 */
const mapToOrderDeliveryAddress = ({ id, name, mainInfo, location, zip, defaultDeliveryAddress }) => ({
  id, name, mainInfo, location, zip, defaultDeliveryAddress
});

/**
 * Maps Address to OrderDeliveryAddress
 * @returns {Promise<RemoteData<OrderDeliveryAddress>>}
 */
const getOrderDeliveryAddresses = () => {
  return getDeliveryAddresses().then(remoteData =>
    mapRemoteData(remoteData, (addresses) => addresses?.map(mapToOrderDeliveryAddress))
  );
};

const OrderDeliveryAddress = createContext();

export const useOrderDeliveryAddress = () => useContext(OrderDeliveryAddress);

export const DeliveryAddressContextProvider = ({ children }) => {
  const [deliveryAddressesRemoteData, setDeliveryAddressesRemoteData] = useState(initial([]));

  const defaultDeliveryAddressRemoteData = useMemo(() => {
    return mapRemoteData(deliveryAddressesRemoteData, (it) => it ? findDefaultDeliveryAddress(it) : null);
  }, [deliveryAddressesRemoteData]);

  useEffect(() => {
    remoteDataWithSetter(setDeliveryAddressesRemoteData, getOrderDeliveryAddresses).then();
  }, []);


  return (
    <OrderDeliveryAddress.Provider value={{ defaultDeliveryAddressRemoteData }}>
      {children}
    </OrderDeliveryAddress.Provider>
  );
};
