import React, {useEffect, useRef, useState} from 'react';
import {
  ButtonFactura,
  ContainerLeft,
  ContainerPrecios,
  ContainerRight,
  EtiquetaPrecio,
  EtiquetasPedido,
  Longcard,
  OrdenPedido,
  Precios,
  PreciosDiv,
  PreciosPedidoEtiqueta,
  ViewCart
} from '../../user/ui/cuenta.elements';
import 'react-toastify/dist/ReactToastify.css';
import {ContainerPDF, Indicador, ParrafoIndicador} from '../../ui';
import {DownloadableOrderRecap} from './DownloadableOrderRecap';
import {useTenantContext} from '../../tenant/TenantContext';
import {downloadCmpAsPdf} from '../../helpers/downloadPdf';
import {ProductsModal} from '../../product';
import {getAll} from '../infrastructure/OrderApi';

function OrderHistory() {
  const tenant = useTenantContext();
  const { primaryColor, secondaryColor } = tenant;
  const [orders, setOrders] = useState([]);
  const [orderData, setOrderData] = useState();
  const [showModals, setShowModals] = useState({});

  const GetOrders = async () => {
    try {
      setOrders(await getAll());
    } catch (error) {
      alert('Error al cargar historial de pedidos');
    }
  };

  const certificateTemplateRef = useRef(null);

  const downloadOrdersPdf = async (order) => {
    setOrderData(order);
    await downloadCmpAsPdf(certificateTemplateRef, `order-${order.numOrden}`);
  };

  useEffect(() => {
    GetOrders()
    .catch(error => console.log(error));
  }, []);

  return (
    <div className="d-flex flex-column gap-5">
      {/* TODO enable when backend would be ready <TransactionsRecap />*/}
      <div>
        <Indicador indicador={'Historial de pedidos'} />
        <ParrafoIndicador
          parrafo={'Revisa el historial de tus pedidos, su estado y descarga tus pedidos.'} />
        <div className="row">
          {orders.map((pedido, index) => (
            <Longcard className="col-md-12" key={index}>
              <div className="row">
                <ContainerLeft className="col-md-6">
                  <EtiquetasPedido>Fecha de pedido</EtiquetasPedido>
                  <EtiquetaPrecio background={secondaryColor}>{pedido.fechaPedido}</EtiquetaPrecio>
                  <OrdenPedido>Numero de pedido: {pedido.numOrden}</OrdenPedido>
                  <EtiquetasPedido></EtiquetasPedido>
                </ContainerLeft>
                <ContainerRight className="col-md-6">
                  <ViewCart
                    color={primaryColor}
                    onClick={() => setShowModals({
                      ...showModals,
                      [pedido.id]: !showModals[pedido.id]
                    })}>
                    {pedido.unidades} productos en este pedido
                  </ViewCart>
                  {showModals[pedido.id] && (
                    <ProductsModal
                      onModalClose={() => setShowModals({
                        ...showModals,
                        [pedido.id]: !showModals[pedido.id]
                      })}
                      products={pedido.articulos} />
                  )}
                  <div className="row">
                    <div className="col-md-12">
                      <ButtonFactura
                        background={primaryColor}
                        onClick={() => downloadOrdersPdf(pedido)}>
                        Descargar pedido
                      </ButtonFactura>
                    </div>
                  </div>
                  <ContainerPrecios>
                    <PreciosDiv>
                    </PreciosDiv>
                    <PreciosDiv>
                      <PreciosPedidoEtiqueta>Descuentos</PreciosPedidoEtiqueta>
                      <Precios background={primaryColor}>{pedido.descuentos}%</Precios>
                    </PreciosDiv>
                    <PreciosDiv>
                      <PreciosPedidoEtiqueta>Subtotal</PreciosPedidoEtiqueta>
                      <Precios>{pedido.subtotal}€</Precios>
                    </PreciosDiv>
                    <PreciosDiv>
                      <PreciosPedidoEtiqueta>Iva</PreciosPedidoEtiqueta>
                      <Precios>{pedido.iva}%</Precios>
                    </PreciosDiv>
                    <PreciosDiv>
                      <PreciosPedidoEtiqueta>Total</PreciosPedidoEtiqueta>
                      <Precios>{pedido.precioTotal}€</Precios>
                    </PreciosDiv>
                  </ContainerPrecios>
                </ContainerRight>
              </div>
            </Longcard>
          ))}
        </div>
        <ContainerPDF ref={certificateTemplateRef}>
          <DownloadableOrderRecap order={orderData} />
        </ContainerPDF>
      </div>
    </div>
  );
}

export {OrderHistory};