import {DeliveryAddressContextProvider, useOrderDeliveryAddress} from '../application/useOrderDeliveryAddress';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
import {OrderConfirmationUI} from './OrderConfirmationUI';
import {CartView, useCartContext} from '../../cart';
import React, {useEffect, useState} from 'react';
import {ConfirmAddressUI} from './ConfirmAddressUI';
import {ContainerTitle, TitleSeccion} from '../../components/Theme/theme.elements';
import {ContainerMenu, RoutePathBreadcrumb} from '../../ui';
import {TextAreaUI} from '../../ui/components/TextAreaUI';
import {OrderRecap} from './OrderRecap';
import {DeliveryAddress} from './DeliveryAddress';
import {placeOrder} from '../infrastructure/OrderApi';
import {isSuccess} from '../../common/domain/RemoteData.model';

export const Checkout = () => {
  return (
    <DeliveryAddressContextProvider>
      <CheckoutContent />
    </DeliveryAddressContextProvider>
  );
};

// TODO: would this component location be better at the cart domain?
const CheckoutContent = () => {
  const { cart, loadCart, validateCart } = useCartContext();
  const { defaultDeliveryAddressRemoteData } = useOrderDeliveryAddress();
  const [placeOrderDisabled, setPlaceOrderDisabled] = useState(false);
  const [comment, setComment] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    setPlaceOrderDisabled(cart?.products?.length <= 0 || cart?.deprecatedProducts?.length > 0 || !isSuccess(defaultDeliveryAddressRemoteData));
  }, [cart, defaultDeliveryAddressRemoteData]);

  const navigate = useNavigate();

  const _placeOrder = async () => {
    toast.info('Realizando pedido.');
    setPlaceOrderDisabled(true);
    setShowConfirmationModal(false);

    validateCart({
      validCartCallback: async () => {
        try {
          const order = await placeOrder(defaultDeliveryAddressRemoteData.value.id, comment);
          navigate('/');
          toast.success(OrderConfirmationUI(order));
        } catch (error) {
          toast.error('Error al realizar el pedido. Contacte con nosotros.');
        } finally {
          loadCart();
        }
      },
      invalidCartCallback: () => {
        toast.warning('Hay productos desactualizados. Por favor, revise su carrito.');
        loadCart();
      }
    });
  };

  const handlePlaceOrder = () => {
    setShowConfirmationModal(true);
  };

  return (
    <>
      <div className="container">
        <div className="my-4">
          <TitleSeccion>Resumen de tu pedido</TitleSeccion>
          <RoutePathBreadcrumb />
        </div>

        <div className="row gap-4 gap-md-0">
          <div className="col-12 col-md-9">
            <CartView />
          </div>
          <div className="col-12 col-md-3">
            <div className="d-flex flex-column">
              <DeliveryAddress />
              <div>
                <ContainerMenu>
                  <ContainerTitle>
                    Observaciones
                  </ContainerTitle>
                  <TextAreaUI
                    data={{
                      value: comment,
                      max: 1000,
                      placeholder: 'Añada comentario sobre su pedido'
                    }}
                    actions={{ handleChange: setComment }}
                  />
                </ContainerMenu>
              </div>
              <OrderRecap />
              <div className="text-center">
                <button type="button"
                        className="btn btn-primary"
                        disabled={placeOrderDisabled}
                        onClick={handlePlaceOrder}>
                  Realizar Pedido
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        showConfirmationModal &&
        <ConfirmAddressUI
          data={{ address: defaultDeliveryAddressRemoteData.value, show: showConfirmationModal }}
          actions={{
            onConfirm: () => _placeOrder(),
            onCancel: () => setShowConfirmationModal(false)
          }}
        />
      }
    </>
  );
};