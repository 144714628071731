import React from 'react';
import {ContainerNuevo, ContainerOptions, Explanation, InputError, InputOption, InputSend, LabelCart, LabelName} from './cuenta.elements';
import {ContenedorGlobal} from '../../components/Theme/theme.elements';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import {useTenantContext} from '../../tenant/TenantContext';
import {useSignup} from '../application/useSignup';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Spinner} from 'react-bootstrap';

export const Register = () => {
  const { tenantId, privacyPolicy } = useTenantContext();
  const { signupRemoteData, signup } = useSignup();

  const validationSchema = Yup.object({
    nombre: Yup.string()
               .required('Este campo es obligatorio')
               .min(2, 'El valor del campo es demasiado corto'),
    empresa: Yup.string()
                .required('Este campo es obligatorio')
                .min(2, 'El valor del campo es demasiado corto'),
    email: Yup.string()
              .email('El email no tiene un formato correcto')
              .required('Este campo es obligatorio'),
    telefono: Yup.string()
                 .required('Este campo es obligatorio')
                 .min(2, 'El valor del campo es demasiado corto')
                 .matches(/^(\+\d{1,3})?\s?-?\d{3}\s?-?\d{3}\s?-?\d{3}$/, 'Introduzca un teléfono válido (ej: 123 456 789 o +34 123 456 789)'),
    nifCif: Yup.string()
               .required('Este campo es obligatorio')
               .min(2, 'El valor del campo es demasiado corto'),
    tipoEmpresa: Yup.string()
                    .required('Este campo es obligatorio')
                    .min(2, 'El valor del campo es demasiado corto'),
    direccion: Yup.string()
                  .required('Este campo es obligatorio')
                  .min(2, 'El valor del campo es demasiado corto'),
    poblacion: Yup.string()
                  .required('Este campo es obligatorio')
                  .min(2, 'El valor del campo es demasiado corto'),
    provincia: Yup.string()
                  .required('Este campo es obligatorio')
                  .min(2, 'El valor del campo es demasiado corto'),
    codigoPostal: Yup.string()
                     .required('Este campo es obligatorio')
                     .min(2, 'El valor del campo es demasiado corto'),
    direccionEnvio: Yup.string(),
    numColegiado: Yup.string()
                     .min(2, 'El valor del campo es demasiado corto'),
    cuentaCorriente: Yup.string()
                        .required('Este campo es obligatorio')
                        .matches(
                          /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/,
                          'IBAN no válido'
                        ),
    recargoEquivalencia: Yup.string()
                            .required('Este campo es obligatorio'),
    facturaElectronica: Yup.boolean(),
    aceptaTerminos: Yup.boolean()
                       .oneOf([true], 'Debe aceptar los términos y condiciones para continuar')
  });

  return (
    <div className="pt-4">
      <ContenedorGlobal className="narrow">
        <ContainerNuevo className="col-md-12 bs coloured" background={'#F0F0F0'}>
          <div className="col-md-12">
            <LabelName>
              Registro para nuevos clientes
            </LabelName>
          </div>

          <RemoteDataUI remoteData={signupRemoteData}>
            <RemoteDataUI.Initial>
              <Formik
                initialValues={{
                  nombre: '',
                  empresa: '',
                  email: '',
                  telefono: '',
                  nifCif: '',
                  tipoEmpresa: '',
                  direccion: '',
                  poblacion: '',
                  provincia: '',
                  codigoPostal: '',
                  direccionEnvio: '',
                  numColegiado: '',
                  cuentaCorriente: '',
                  recargoEquivalencia: '1',
                  facturaElectronica: false,
                  aceptaTerminos: false
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => signup(values, tenantId)}>
                {({ handleChange, handleBlur, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <ContainerOptions className="col-md-12">
                        <LabelCart className="mandatory">Nombre y apellidos</LabelCart>
                        <InputOption type="text" id="nombre" value={values.nombre} onChange={handleChange} onBlur={handleBlur} />
                        {errors.nombre && touched.nombre && (<InputError>{errors.nombre}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-12">
                        <LabelCart className="mandatory">Razón social de la empresa</LabelCart>
                        <InputOption type="text" id="empresa" value={values.empresa} onChange={handleChange} onBlur={handleBlur} />
                        {errors.empresa && touched.empresa && (<InputError>{errors.empresa}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-6">
                        <LabelCart className="mandatory">EMail</LabelCart>
                        <InputOption type="text" id="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                        {errors.email && touched.email && (<InputError>{errors.email}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-6">
                        <LabelCart className="mandatory">Telefono</LabelCart>
                        <InputOption type="text" id="telefono" value={values.telefono} onChange={handleChange} onBlur={handleBlur} />
                        {errors.telefono && touched.telefono && (<InputError>{errors.telefono}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-6">
                        <LabelCart className="mandatory">CIF / NIF</LabelCart>
                        <InputOption type="text" id="nifCif" value={values.nifCif} onChange={handleChange} onBlur={handleBlur} />
                        {errors.nifCif && touched.nifCif && (<InputError>{errors.nifCif}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-6">
                        <LabelCart className="mandatory">Tipo de empresa</LabelCart>
                        <InputOption type="text" id="tipoEmpresa" value={values.tipoEmpresa} onChange={handleChange} onBlur={handleBlur} />
                        <Explanation>Ejemplo: Clínica, tienda...</Explanation>
                        {errors.tipoEmpresa && touched.tipoEmpresa && (<InputError>{errors.tipoEmpresa}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-12">
                        <LabelCart className="mandatory">Dirección</LabelCart>
                        <InputOption type="text" id="direccion" value={values.direccion} onChange={handleChange} onBlur={handleBlur} />
                        {errors.direccion && touched.direccion && (<InputError>{errors.direccion}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-5">
                        <LabelCart className="mandatory">Población</LabelCart>
                        <InputOption type="text" id="poblacion" value={values.poblacion} onChange={handleChange} onBlur={handleBlur} />
                        {errors.poblacion && touched.poblacion && (<InputError>{errors.poblacion}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-4">
                        <LabelCart className="mandatory">Provincia</LabelCart>
                        <InputOption type="text" id="provincia" value={values.provincia} onChange={handleChange} onBlur={handleBlur} />
                        {errors.provincia && touched.provincia && (<InputError>{errors.provincia}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-3">
                        <LabelCart className="mandatory">CP</LabelCart>
                        <InputOption type="text" id="codigoPostal" value={values.codigoPostal} onChange={handleChange} onBlur={handleBlur} />
                        {errors.codigoPostal && touched.codigoPostal && (<InputError>{errors.codigoPostal}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-12">
                        <LabelCart>Dirección de envío</LabelCart>
                        <InputOption type="text" id="direccionEnvio" value={values.direccionEnvio} onChange={handleChange} onBlur={handleBlur} />
                        <Explanation>Sólo si es distinto a la dirección de la empresa</Explanation>
                        {errors.direccionEnvio && touched.direccionEnvio && (<InputError>{errors.direccionEnvio}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-6">
                        <LabelCart>Número de colegiado</LabelCart>
                        <InputOption type="text" id="numColegiado" value={values.numColegiado} onChange={handleChange} onBlur={handleBlur} />
                        {errors.numColegiado && touched.numColegiado && (<InputError>{errors.numColegiado}</InputError>)}
                      </ContainerOptions>


                      <ContainerOptions className="col-md-6">
                        <LabelCart className="mandatory">IBAN para domiciliación SEPA</LabelCart>
                        <InputOption type="text" id="cuentaCorriente" value={values.iban} onChange={handleChange} onBlur={handleBlur} />
                        <Explanation>Sin espacios ni símbolos de separación</Explanation>
                        {errors.cuentaCorriente && touched.cuentaCorriente && (<InputError>{errors.cuentaCorriente}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-12">

                        <LabelCart>Recargo de equivalencia</LabelCart>
                        <select id="recargoEquivalencia" name="recargoEquivalencia" value={values.recargoEquivalencia} onChange={handleChange} onBlur={handleBlur}>
                          <option value="1">Sin recargo de equivalencia</option>
                          <option value="2">Con recargo de equivalencia</option>
                          <option value="3">Ambas opciones</option>
                        </select>

                        {errors.recargoEquivalencia && touched.recargoEquivalencia && (<InputError>{errors.recargoEquivalencia}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-12 mt-4">
                        <input type="checkbox" id="facturaElectronica" value={values.facturaElectronica} onChange={handleChange} onBlur={handleBlur} />
                        <label htmlFor="facturaElectronica">Quiero factura electrónica</label>
                        {errors.facturaElectronica && touched.facturaElectronica && (<InputError>{errors.facturaElectronica}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-12">
                        <input type="checkbox" id="aceptaTerminos" value={values.lopd} onChange={handleChange} onBlur={handleBlur} />
                        <label htmlFor="aceptaTerminos">
                          Acepto la LOPD, los <a href={privacyPolicy} target="_blank">términos y condiciones de LEONVET SL</a>
                        </label>
                        {errors.aceptaTerminos && touched.aceptaTerminos && (<InputError>{errors.aceptaTerminos}</InputError>)}
                      </ContainerOptions>
                    </div>
                    <div className="mt-4 explanation"><p>Ten en cuenta que esto no es un proceso automatizado, nuestro personal revisará tu solicitud lo antes posible, podemos
                      tardar hasta 48 horas. Gracias por confiar en Leonvet.</p></div>

                    <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-end gap-2 submit-row">
                      <InputSend type="submit" value="Continuar registro" />
                      <div className="order-md-first text-center">
                        <Link to="/iniciar-sesion">
                          Volver a inicio de sesión
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </RemoteDataUI.Initial>

            <RemoteDataUI.Pending>
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            </RemoteDataUI.Pending>

            <RemoteDataUI.Success>
              {
                () => (

                  <div className="successMessage text-success">
                    <p>Hemos recibido correctamente su solicitud, y contactaremos muy pronto para confirmar el registro.</p>
                    <p>Gracias por su interés en LeonVet</p>
                  </div>
                )
              }
            </RemoteDataUI.Success>

            <RemoteDataUI.Failure>
              <div className="errorMessage">
                <p>Ha ocurrido un error. Por favor, reintente más tarde o contacte con nosotros</p>
                <p>{signupRemoteData.error}</p>
              </div>
            </RemoteDataUI.Failure>
          </RemoteDataUI>
        </ContainerNuevo>
      </ContenedorGlobal>
    </div>
  );
};
