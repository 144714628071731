import React from 'react';
import {Route, Routes} from 'react-router-dom';
import PrivatePage from './Pages/PrivatePage';
import {Home} from './home/components/home';
import {AccountRoutes} from './user/AccountRoutes';
import {Checkout} from './order/ui/Checkout';
import {Courses} from './courses/components/courses/Courses';
import {CourseDetails} from './courses/components/course-details/CourseDetails';
import {ManufacturerRoutes} from './manufacturer';
import {PublicPage} from './Pages/PublicPage';
import {Register} from './user/ui/Register';
import {Onboard} from './user/ui/Onboard';
import {AwsCustomAuthenticator} from './ui/components/AwsCustomAuthenticator';
import {ProductDetailsWithRelatedCatalogItemsPage} from './high-order-context/ProductDetailsWithRelatedCatalogItems';
import {CatalogPage} from './catalog/ui/CatalogPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivatePage />}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/articulos" element={<CatalogPage />} />
        <Route exact path="/articulos/:productCode" element={<ProductDetailsWithRelatedCatalogItemsPage />} />
        <Route exact path="/cuenta/*" element={<AccountRoutes />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/formacion" element={<Courses />} />
        <Route exact path="/formacion/:id" element={<CourseDetails />} />
        <Route exact path="/laboratorios/*" element={<ManufacturerRoutes />} />
      </Route>

      <Route element={<PublicPage />}>
        <Route exact path="/registro" element={<Register />} />
        <Route exact path="/registroclientes" element={<Onboard />} />
      </Route>

      <Route exact path="/iniciar-sesion" element={<AwsCustomAuthenticator />} />
    </Routes>
  );
};

export {AppRoutes};